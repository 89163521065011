/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        objectFitImages();

        // Find all YouTube videos
        var $allVideos = jQuery("iframe[src*='//www.youtube.com']"),
            $fluidEl = jQuery("main");
        $allVideos.each(function() {
          jQuery(this)
            .data('aspectRatio', this.height / this.width)
            .removeAttr('height')
            .removeAttr('width');
        });
        jQuery(window).resize(function() {
          var newWidth = $fluidEl.width();
          $allVideos.each(function() {
            var $el = jQuery(this);
            $el
              .width(newWidth)
              .height(newWidth * $el.data('aspectRatio'));
          });
        }).resize();

        // lazy load elements
        lazyLoadInit({root: null, rootMargin: '200px'});

        //Main menu slidedown submenu
        $('.navbar-nav > li > ul.dropdown-menu').parent().addClass('has-submenu');
        $('.navbar-nav .caret').click(function(e) {
          e.preventDefault();
          $(this).parents('.dropdown').find('.dropdown-menu:first').slideToggle('fast');
        });

        if ($().hoverIntent) {
          $(".has-submenu").hoverIntent({
            over: function () {
              if ($(window).width() >= 768 ) {
                $('.dropdown-menu:first', this).slideDown('fast');
              }
            },
            out: function () {
              if ($(window).width() >= 768 ) {
                $('.dropdown-menu:first', this).hide();
              }
            },
            timeout: 200
          });
        }

        // add icons to sub-submenu
        $('.dropdown-menu ul ul a').prepend('<span class="icon-angle-right"></span>');

        // expand intro
        var curLang = location.href.indexOf('/en/') != -1 ? 'en': 'nl',
            btnLbl = {
              hide: {nl: 'Verberg', en: 'Hide'},
              show: {nl: 'Lees meer', en: 'Read more'}
            };
        $('.intro-collapse').after('<a href="#" class="collapse-intro-btn btn">'+btnLbl.show[curLang]+'</a>');
        $('.collapse-intro-btn').on('click', function(e) {
          e.preventDefault();
          var $content = $('.intro-collapse');
          if (!$content.hasClass('expanded')) {
            $content.css('height', $content[0].scrollHeight);
            $(this).text(btnLbl.hide[curLang]);
          } else {
            $content.css('height', '');
            $(this).text(btnLbl.show[curLang]);
          }
          $content.toggleClass('expanded');
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
